import type { Commit, Dispatch } from "vuex";
import type Dashboard from "@/types/Dashboard";
import type DashboardTile from "@/types/DashboardTile";
import pubApi from "@/api/pub";
import debounce from "lodash/debounce";
import * as types from "./mutation-types";
import { flexDashboardService } from "@/api/flex";

interface State {
  isLoading: boolean;
  activeDashboard: null | Dashboard;
  allDashboards: Array<Dashboard>;
  selectedInventory: string;
  dimensionCategories: string[];
}

const state: State = {
  isLoading: false,
  activeDashboard: null,
  allDashboards: [],
  selectedInventory:
    localStorage.getItem("selectedInventory") || "display_video", // Load from localStorage
  dimensionCategories: [
    "Time Unit",
    "Site",
    "Inventory",
    "Geography",
    "User Environment",
    "Traffic",
    "Auction",
    "Commercial Arrangements",
    "Privacy",
    "Other",
  ],
};

const getters = {
  activeDashboardId: (state: State) => state.activeDashboard?.id,
  activeEnvironment: (state: State) => state.activeDashboard?.environment,
  dashboardTiles: (state: State) => state.activeDashboard?.tiles || [],
  tileById: (state: State) => (id: number) =>
    state.activeDashboard?.tiles.find((tile) => tile.id === id),
  tileByPositionIndex: (state: State) => (positionIndex: number) =>
    state.activeDashboard?.tiles.find(
      (tile) => tile.positionIndex === positionIndex
    ),
  newPositionIndex: (state: State) => {
    const tileIndices =
      state.activeDashboard && state.activeDashboard.tiles.length
        ? state.activeDashboard.tiles.map((tile) => tile.positionIndex)
        : [0];
    return Math.max(...tileIndices) + 1;
  },
  overviewSelection: (state: State) =>
    state.activeDashboard?.overviewSelection ?? [],
  overviewMetrics: (state: State) =>
    state.activeDashboard?.overviewMetrics ?? [],
  overviewDaterange: (state: State) =>
    state.activeDashboard?.overviewDaterange ?? "LAST_30",
  selectedInventory: (state: State) => state.selectedInventory,
  dimensionCategories: (state: State) => state.dimensionCategories,
};

/**
 * Ensure that comparisonMetrics is always parsed as an array
 *
 * @param dashboards
 */
function ensuredComparisonMetricsIsAnArray(
  dashboards: Dashboard[]
): Dashboard[] {
  dashboards.forEach((dashboard) => {
    dashboard.tiles.forEach((tile) => {
      if (
        tile.comparisonMetrics &&
        typeof tile.comparisonMetrics === "string"
      ) {
        try {
          tile.comparisonMetrics = JSON.parse(tile.comparisonMetrics);
        } catch (error) {
          console.error("Error parsing comparisonMetrics", error);
          tile.comparisonMetrics = [];
        }
      } else if (!tile.comparisonMetrics) {
        tile.comparisonMetrics = [];
      }
    });
  });
  return dashboards;
}

const actions = {
  async fetchAllDashboards({ commit }: { commit: Commit }, userId: number) {
    commit(types.SET_IS_LOADING, true);
    const dashboards = await pubApi.dashboards.getAllByUserId(userId);
    // Commit the dashboards to the store
    commit(
      types.SET_ALL_DASHBOARDS,
      ensuredComparisonMetricsIsAnArray(dashboards)
    );
    commit(types.SET_IS_LOADING, false);
    return dashboards;
  },

  async fetchAllFlexDashboards({ commit }: { commit: Commit }, userId: number) {
    commit(types.SET_IS_LOADING, true);
    const dashboards = await flexDashboardService.getDashboardByUserId(userId);
    // Commit the dashboards to the store
    commit(
      types.SET_ALL_DASHBOARDS,
      ensuredComparisonMetricsIsAnArray(dashboards)
    );
    commit(types.SET_IS_LOADING, false);
    return dashboards;
  },

  async createFromDefaults({ commit }: { commit: Commit }, userId: number) {
    commit(types.SET_IS_LOADING, true);
    const dashboards = await pubApi.dashboards.createFromDefaults(userId);
    commit(types.SET_ALL_DASHBOARDS, dashboards);
    commit(types.SET_IS_LOADING, false);
  },
  async createFlexFromDefaults({ commit }: { commit: Commit }, userId: number) {
    commit(types.SET_IS_LOADING, true);
    const dashboards = await flexDashboardService.createFromDefaults(userId);
    commit(types.SET_ALL_DASHBOARDS, dashboards);
    commit(types.SET_IS_LOADING, false);
  },

  async updateDashboard(
    { commit, state }: { commit: Commit; state: State },
    { userId, updateLocalState }: { userId: number; updateLocalState: boolean }
  ) {
    if (state.activeDashboard) {
      const updatedDashboard = await pubApi.dashboards.updateWithTiles(
        userId,
        state.activeDashboard
      );
      if (updateLocalState) {
        commit(types.RECEIVE_DASHBOARD, updatedDashboard);
      }
      commit(types.SET_ACTIVE_DASHBOARD, updatedDashboard.environment);
    }
  },
  async resetDashboard(
    { commit, state }: { commit: Commit; state: State },
    userId: number
  ) {
    commit(types.SET_IS_LOADING, true);
    const dashboardId = state.activeDashboard?.id;
    if (dashboardId) {
      const updatedDashboard = await pubApi.dashboards.resetDashboard(
        userId,
        dashboardId
      );
      commit(types.RECEIVE_DASHBOARD, updatedDashboard);
      commit(types.SET_ACTIVE_DASHBOARD, updatedDashboard.environment);
      commit(types.UPDATE_SELECTED_INVENTORY, "display_video");
    }
    commit(types.SET_IS_LOADING, false);
  },
  async resetFlexDashboard(
    { commit, state }: { commit: Commit; state: State },
    userId: number
  ) {
    commit(types.SET_IS_LOADING, true);
    const dashboardId = state.activeDashboard?.id;
    if (dashboardId) {
      const updatedDashboard = await flexDashboardService.resetDashboard(
        userId,
        dashboardId
      );
      commit(types.RECEIVE_DASHBOARD, updatedDashboard);
      commit(types.SET_ACTIVE_DASHBOARD, updatedDashboard.environment);
      commit(types.UPDATE_SELECTED_INVENTORY, "display_video");
    }
    commit(types.SET_IS_LOADING, false);
  },
  // Delays execution of remote save until it hasn't been called for at least 1000ms
  // Intent is that rapidly changing tile config won't trigger a million saves
  // State is immediately updated locally here in the store
  debounceUpdateDashboard: debounce(
    ({ dispatch }: { dispatch: Dispatch }, userId: number) => {
      dispatch("updateDashboard", { userId, updateLocalState: false });
    },
    1000
  ),
  removeTile({ commit }: { commit: Commit }, tileId: number) {
    commit(types.REMOVE_DASHBOARD_TILE, tileId);
  },
  moveTileDown(
    { commit, getters }: { commit: Commit; getters: any },
    tileId: number
  ) {
    const tileA = { ...getters.tileById(tileId) };
    const tileB = {
      ...getters.tileByPositionIndex(tileA.positionIndex + 1),
    };
    if (Object.keys(tileB).length > 0) {
      [tileA.positionIndex, tileB.positionIndex] = [
        tileB.positionIndex,
        tileA.positionIndex,
      ];
      commit(types.UPDATE_DASHBOARD_TILE, tileA);
      commit(types.UPDATE_DASHBOARD_TILE, tileB);
    }
  },
  moveTileUp(
    { commit, getters }: { commit: Commit; getters: any },
    tileId: number
  ) {
    const tileA = { ...getters.tileById(tileId) };
    const tileB = {
      ...getters.tileByPositionIndex(tileA.positionIndex - 1),
    };
    if (Object.keys(tileB).length > 0) {
      [tileA.positionIndex, tileB.positionIndex] = [
        tileB.positionIndex,
        tileA.positionIndex,
      ];
      commit(types.UPDATE_DASHBOARD_TILE, tileA);
      commit(types.UPDATE_DASHBOARD_TILE, tileB);
    }
  },
};

const mutations = {
  [types.SET_IS_LOADING](state: State, isLoading: boolean) {
    state.isLoading = isLoading;
  },
  [types.SET_ACTIVE_DASHBOARD](state: State, env: string) {
    const foundDashboard = state.allDashboards.find(
      (d) => d.environment === env
    );
    if (foundDashboard) {
      state.activeDashboard = foundDashboard;
    }
  },
  // Replace a dashboard from the array with one that's been freshly loaded from API
  [types.RECEIVE_DASHBOARD](state: State, dashboard: Dashboard) {
    state.allDashboards = [
      ...state.allDashboards.filter((d) => d.id !== dashboard.id),
      dashboard,
    ];
    // state.allDashboards.push(dashboard);
  },
  // Set the entire dashboard array after loading them all from the API
  [types.SET_ALL_DASHBOARDS](state: State, dashboards: Dashboard[]) {
    state.allDashboards = dashboards;
  },
  [types.UPDATE_OVERVIEW_SELECTION](state: State, selection: string[]) {
    if (state.activeDashboard) {
      state.activeDashboard.overviewSelection = selection;
    }
  },
  [types.UPDATE_OVERVIEW_METRICS](state: State, metrics: string[]) {
    if (state.activeDashboard) {
      state.activeDashboard.overviewMetrics = metrics;
    }
  },
  [types.UPDATE_OVERVIEW_DATERANGE](state: State, dateRange: string) {
    if (state.activeDashboard) {
      state.activeDashboard.overviewDaterange = dateRange;
    }
  },
  [types.UPDATE_BREAKDOWN_MEASURES](
    state: State,
    tile: Pick<DashboardTile, "id" | "breakdownMeasures">
  ) {
    const tileToUpdate = state.activeDashboard?.tiles.find(
      (t) => t.id === tile.id
    );
    if (tileToUpdate) {
      tileToUpdate.breakdownMeasures = tile.breakdownMeasures;
    }
  },
  [types.UPDATE_BREAKDOWN_DIMENSIONS](
    state: State,
    tile: Pick<DashboardTile, "id" | "breakdownDimensions">
  ) {
    const tileToUpdate = state.activeDashboard?.tiles.find(
      (t) => t.id === tile.id
    );
    if (tileToUpdate) {
      tileToUpdate.breakdownDimensions = tile.breakdownDimensions;
    }
  },
  [types.UPDATE_QUERY](
    state: State,
    tile: Pick<DashboardTile, "id" | "measures" | "dimensions" | "dateRange">
  ) {
    const tileToUpdate = state.activeDashboard?.tiles.find(
      (t) => t.id === tile.id
    );
    if (tileToUpdate) {
      tileToUpdate.measures = tile.measures;
      tileToUpdate.dimensions = tile.dimensions;
      tileToUpdate.dateRange = tile.dateRange;
    }
  },
  [types.UPDATE_MEASURES](
    state: State,
    tile: Pick<DashboardTile, "id" | "measures">
  ) {
    const tileToUpdate = state.activeDashboard?.tiles.find(
      (t) => t.id === tile.id
    );
    if (tileToUpdate) {
      tileToUpdate.measures = tile.measures;
    }
  },
  [types.UPDATE_DIMENSIONS](
    state: State,
    tile: Pick<DashboardTile, "id" | "dimensions">
  ) {
    const tileToUpdate = state.activeDashboard?.tiles.find(
      (t) => t.id === tile.id
    );
    if (tileToUpdate) {
      tileToUpdate.dimensions = tile.dimensions;
    }
  },
  [types.UPDATE_INVENTORY](
    state: State,
    tile: Pick<DashboardTile, "id" | "inventory">
  ) {
    const tileToUpdate = state.activeDashboard?.tiles.find(
      (t) => t.id === tile.id
    );
    if (tileToUpdate) {
      tileToUpdate.inventory = tile.inventory;
    }
  },
  [types.UPDATE_DATE_RANGE](
    state: State,
    tile: Pick<DashboardTile, "id" | "dateRange">
  ) {
    const tileToUpdate = state.activeDashboard?.tiles.find(
      (t) => t.id === tile.id
    );
    if (tileToUpdate && tile.dateRange) {
      tileToUpdate.dateRange = tile.dateRange;
    }
  },
  [types.UPDATE_ALL_TILES_INVENTORY](state: State, inventory: string) {
    state.selectedInventory = inventory;
    localStorage.setItem("selectedInventory", inventory); // Save to localStorage
    if (state.activeDashboard) {
      for (const tile of state.activeDashboard.tiles) {
        if (inventory === "display_video") {
          tile.inventory = ["display", "video"];
        } else {
          tile.inventory = [inventory];
        }
      }
    }
  },
  [types.UPDATE_ALL_TILES_DATE_RANGE](state: State, dateRange: string) {
    state.activeDashboard?.tiles.forEach(
      (tile) => (tile.dateRange = dateRange)
    );
  },
  [types.UPDATE_CUSTOM_DATE_RANGE](
    state: State,
    tile: Pick<DashboardTile, "id" | "customDateRange">
  ) {
    const tileToUpdate = state.activeDashboard?.tiles.find(
      (t) => t.id === tile.id
    );
    if (tileToUpdate && tile.customDateRange.length === 2) {
      tileToUpdate.dateRange = "CUSTOM";
      tileToUpdate.customDateRange = tile.customDateRange;
    }
  },
  [types.UPDATE_CHART_TYPE](
    state: State,
    tile: Pick<DashboardTile, "id" | "chartType">
  ) {
    const tileToUpdate = state.activeDashboard?.tiles.find(
      (t) => t.id === tile.id
    );
    if (tileToUpdate) {
      tileToUpdate.chartType = tile.chartType;
    }
  },
  [types.UPDATE_TILE_TITLE](
    state: State,
    tile: Pick<DashboardTile, "id" | "title">
  ) {
    const tileToUpdate = state.activeDashboard?.tiles.find(
      (t) => t.id === tile.id
    );
    if (tileToUpdate) {
      tileToUpdate.title = tile.title;
    }
  },
  [types.UPDATE_TABLE_PAGE_SIZE](
    state: State,
    tile: Pick<DashboardTile, "id" | "tablePageSize">
  ) {
    const tileToUpdate = state.activeDashboard?.tiles.find(
      (t) => t.id === tile.id
    );
    if (tileToUpdate) {
      tileToUpdate.tablePageSize = tile.tablePageSize;
    }
  },
  [types.SET_COMPARISON_STATUS](
    state: State,
    tile: Pick<DashboardTile, "id" | "comparisonActive">
  ) {
    const tileToUpdate = state.activeDashboard?.tiles.find(
      (t) => t.id === tile.id
    );
    if (tileToUpdate) {
      tileToUpdate.comparisonActive = tile.comparisonActive;
    }
  },
  [types.UPDATE_COMPARISON_METRICS](
    state: State,
    tile: Pick<DashboardTile, "id" | "comparisonMetrics">
  ) {
    const tileToUpdate = state.activeDashboard?.tiles.find(
      (t) => t.id === tile.id
    );
    if (tileToUpdate) {
      tileToUpdate.comparisonMetrics = tile.comparisonMetrics;
    }
  },
  [types.NEW_DASHBOARD_TILE](state: State, tile: DashboardTile) {
    state.activeDashboard?.tiles.push(tile);
  },
  [types.REMOVE_DASHBOARD_TILE](state: State, id: DashboardTile["id"]) {
    const index: number =
      state.activeDashboard?.tiles?.findIndex((tile) => tile.id === id) ?? -1;
    if (index < 0) return;
    const positionIndex =
      state.activeDashboard?.tiles[index]?.positionIndex ?? -1;
    // recalculate positionIndex for every item that has a greater value than the removed one.
    if (positionIndex >= 0) {
      state.activeDashboard?.tiles?.forEach((tile: DashboardTile) => {
        if (tile.positionIndex >= positionIndex) {
          tile.positionIndex--;
        }
      });
    }
    state.activeDashboard?.tiles.splice(index, 1);
  },
  [types.UPDATE_DASHBOARD_TILE](state: State, tile: DashboardTile) {
    const index: number =
      state.activeDashboard?.tiles?.findIndex((t) => t.id === tile.id) ?? -1;
    if (index > -1) {
      state.activeDashboard?.tiles.splice(index, 1, tile);
    }
  },
  [types.UPDATE_SELECTED_INVENTORY](state: State, selectedInventory: string) {
    state.selectedInventory = selectedInventory;
    localStorage.setItem("selectedInventory", selectedInventory); // Save to localStorage
  },
  [types.REMOVE_ALL_DASHBOARD_TILES](state: State) {
    state.activeDashboard = null;
    state.allDashboards = [];
  },
};

/**
 * @see https://vuex.vuejs.org/en/modules.html
 */
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
