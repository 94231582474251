import {
  createRouter,
  createWebHistory,
  type Router,
  RouterView,
} from "vue-router";
import store from "../store";
import getCookieMixin from "../mixins/get-cookie";
import auth from "../api/auth0";
import tfa from "../api/tfa";
import fonts from "../lib/helpers/fonts";
import raven from "../lib/helpers/raven";
import config from "../config/config";
import flexRedirect from "./guards/flexRedirect";
import checkAuthBefore from "./guards/auth";
import checkRolesBefore from "./guards/roles";
import redirectIfAuthenticated from "./guards/loginRedirect.js";
import checkSitesAndCompanies from "./guards/sitesAndCompanies";
import setTitle from "./guards/setTitle";
import googleAnalyticsAfterHook from "./after/google-analytics";
import pendoInitAfterHook from "./after/pendo";
import userpilotIdentifyAfterHook from "@/router/after/userpilot";
import { pendoDisable } from "@/lib/helpers/pendo";
import { userpilotDestroy } from "@/lib/helpers/userpilot";
import { useAbTestStore } from "@/stores/abTests";
import { useAbTestViewStore } from "@/stores/abTestView";
import { createBreadcrumbGuard } from "@/router/guards/breadcrumbs";
import { useIdentityProvidersStore } from "@/stores/identityProvider";
import type { App } from "vue";
import type Site from "@/types/Site";
import { userImpersonationGuard } from "@/router/guards/userImpersonation";

interface routerInstance extends Router {
  app?: App;
}

const router: routerInstance = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/co-fallback",
      name: "auth0.co.fallback",
      meta: { requiresAuth: false },
      beforeEnter: () => {
        auth.crossOriginVerification();
      },
      component: () => null,
    },
    {
      path: "/callback",
      name: "auth0.callback",
      meta: { requiresAuth: false },
      beforeEnter: (to, from, next) => {
        if (/access_token|id_token|error/.test(to.hash)) {
          raven.captureBreadcrumb("Login", "/callback", "Route", {});
          // Parse auth data
          store
            .dispatch("users/authParseHash")
            .then(async () => {
              // Try to set the tfa cookie and check for ads.txt update.
              // If it fails, just log and move on
              try {
                const d = new Date();
                d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
                const profile = store.getters["users/profile"];
                const pipeInd = profile.sub.indexOf("|");
                const userId = Number(profile.sub.substring(pipeInd + 1));
                const email = store.getters["users/email"].toLowerCase();
                let userAgent = navigator.userAgent.replace(";", "");
                userAgent = userAgent.substring(
                  userAgent.indexOf("(") + 1,
                  userAgent.indexOf(")")
                );
                const fontString = fonts.detect(fonts.list);
                const tfaPerm =
                  getCookieMixin.methods.getCookie("fs_tfa_perm") === "true";
                const tfaData = `${email}|${userAgent}|${fontString}`;
                // Set the tfa cookie
                if (tfaPerm) {
                  tfa.encryptCookie(tfaData).then((res: any) => {
                    document.cookie = `fs_tfa_${email}=${
                      res.encrypted
                    };expires=${d.toUTCString()};path=/`;
                    document.cookie = `fs_tfa_perm=false;expires=${d.toUTCString()};path=/`;
                  });
                }

                // fetch user's profile by user ID.
                await store.dispatch("profiles/fetchProfile", {
                  userId,
                  lastActiveEnvironment: store.getters["users/isFlexUser"]
                    ? "FLEX_PREBID"
                    : "WEB",
                });
              } catch (e) {
                console.log(e); /* eslint-disable-line no-console */
              }

              // Route according to role
              if (store.getters["users/isFlexUser"]) {
                // Trumpet. if there's a site in onboarding
                // state, send user to that section. Otherwise, follow
                // the normal flow.
                // https://freestar.atlassian.net/browse/BITOPS-8677
                const accountId = store.getters["users/accountId"];
                const { sites } = await store.dispatch(
                  "accounts/getFlexSitesForAccount",
                  accountId
                );
                const onboardingSites = sites.find(
                  (s: Site) => Boolean(s.podId) && s.onboardingOpen
                );

                if (onboardingSites) {
                  return next({ name: "flex.onboarding" });
                }
                return next({ name: "flex.dashboard" });
              } else if (localStorage.getItem("fs_route")) {
                // if user has publisher site config user role will always redirect to site config
                if (store.getters["users/isPublisherSiteConfigUser"]) {
                  return next({ name: "site.config" });
                }
                return next({
                  path: localStorage.getItem("fs_route") as string,
                });
              } else if (
                store.getters["users/isAdmin"] ||
                store.getters["users/isPublisher"] ||
                store.getters["isAdRecoveryStandalone"]
              ) {
                // Trumpet. if there's a site in onboarding
                // state, send user to that section. Otherwise, follow
                // the normal flow
                // https://freestar.atlassian.net/browse/BITOPS-8677
                const accountId = store.getters["users/accountId"];
                const { sites } = await store.dispatch(
                  "accounts/getSitesForAccount",
                  accountId
                );
                const onboardingSites = sites.find(
                  (s: Site) => Boolean(s.podId) && s.onboardingOpen
                );

                if (onboardingSites) {
                  return next({ name: "onboarding" });
                }

                return next({ name: "dashboard" });
              } else if (store.getters["users/isPublisherSiteConfigUser"]) {
                return next({ name: "site.config" });
              }

              // no valid role. Redirect to login
              throw new Error("Invalid role");
            })
            .catch(() => {
              next({ name: "login" });
            });
        } else {
          auth.authorize({
            // eslint-disable-next-line no-undef
            redirectUri: config.auth0.callbackUrl,
          });
        }
      },
      component: () => null,
    },
    {
      path: "/createCallback",
      name: "create.callback",
      meta: { requiresAuth: false },
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("fs_route")) {
          return next({ path: localStorage.getItem("fs_route") as string });
        } else if (
          store.getters["users/isAdmin"] ||
          store.getters["users/isPublisher"] ||
          store.getters["users/isAdRecoveryStandalone"]
        ) {
          return next({ name: "dashboard" });
        }
        throw new Error("Invalid role");
      },
      component: () => null,
    },
    {
      path: "/logout",
      name: "logout",
      meta: { requiresAuth: false },
      beforeEnter: (to, from, next) => {
        store.dispatch("users/clearIntendedRoute").then(() => {
          store.dispatch("users/signOut").then(() => next({ name: "login" }));
        });
        pendoDisable();
        userpilotDestroy();
      },
      component: () => null,
    },
    {
      path: "/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "unauth" */ "../views/LoginForm.vue"),
      meta: {
        requiresAuth: false,
        title: "Freestar | Login",
      },
    },
    {
      path: "/reset-password",
      name: "reset.password",
      component: () =>
        import(/* webpackChunkName: "unauth" */ "../views/ResetPassword.vue"),
      meta: {
        requiresAuth: false,
        title: "Freestar | Reset Password",
      },
    },
    // Had to create a redirect here because Auth0 otherwise
    // appends the users email and a long message to the URL
    // User is sent to this route upon successfully resetting their password
    {
      path: "/reset-password/complete",
      name: "reset.password.complete",
      component: () =>
        import(/* webpackChunkName: "unauth" */ "../views/ResetPassword.vue"),
      meta: {
        requiresAuth: false,
        title: "Freestar | Reset Password",
      },
      beforeEnter: (to, from, next) => {
        next({ name: "login" });
      },
    },
    {
      path: "/",
      alias: "/dashboard",
      name: "dashboard",
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ "../views/PublisherDashboard.vue"
        ),
      meta: {
        requiresAuth: true,
        roles: ["publisher", "administrator", "fsr_standalone"],
        displayName: "Dashboard",
        title: "Freestar | Dashboard",
      },
      beforeEnter: (to, from, next) => {
        // Populate account/domain selectors
        // const accountId = store.getters["users/accountId"];
        // store.dispatch("accounts/getSitesForAccount", accountId);
        next();
      },
    },
    {
      path: "/advanced-reporting",
      name: "advanced.reporting",
      meta: { requiresAuth: true },
      component: () => import("../views/reporting/AdvancedReporting.vue"),
      children: [
        {
          path: "",
          name: "reports.list",
          component: () => import("../views/reporting/ReportsList.vue"),
        },
        {
          path: ":id/edit",
          name: "edit.report",
          component: () => import("../views/reporting/EditReport.vue"),
        },
        {
          path: "create",
          name: "create.report",
          component: () => import("../views/reporting/EditReport.vue"),
        },
        {
          path: "results/:id?",
          name: "report.results",
          component: () => import("../views/reporting/ReportResults.vue"),
        },
      ],
    },
    {
      path: "/site-config",
      name: "site.config",
      meta: { requiresAuth: true },
      component: () => import("../views/SiteConfig.vue"),
      children: [
        {
          path: "ads-txt",
          name: "ads.txt",
          alias: ["", "/"],
          component: () => import("../components/siteconfig/AdsTxt.vue"),
        },
        {
          path: "app-ads-txt",
          name: "app.ads.txt",
          component: () => import("../components/siteconfig/AdsTxt.vue"),
        },
        {
          path: "ad-tags",
          name: "ad.tags",
          component: () => import("../components/siteconfig/AdTags.vue"),
        },
        {
          path: "amp",
          name: "amp.config",
          component: () =>
            import("../components/siteconfig/AmpConfigDetails.vue"),
        },
        {
          path: "api",
          name: "api.token.config",
          component: () =>
            import("../components/siteconfig/ApiTokenConfig.vue"),
        },
        {
          path: "ad-block",
          name: "ad.block.recovery",
          component: () =>
            import("../components/siteconfig/AdBlockRecovery.vue"),
        },
      ],
    },
    {
      path: "/payments",
      name: "payments",
      meta: { requiresAuth: true },
      component: () => import("../views/MyPayments.vue"),
    },
    {
      path: "/my-documents",
      name: "my.documents",
      meta: { requiresAuth: true },
      component: () => import("../views/MyDocuments.vue"),
    },
    {
      path: "/onboarding",
      name: "onboarding",
      meta: {
        requiresAuth: true,
        roles: ["flex_admin", "flex_viewer", "administrator", "publisher"],
        title: "Onboarding",
      },
      component: () => import("../views/PublisherOnboarding.vue"),
    },
    {
      path: "/flex",
      redirect: { name: "flex.dashboard" },
      meta: {
        abstract: true,
        requiresAuth: true,
        roles: ["administrator", "flex_admin", "flex_viewer"],
      },
      component: () => import("../layouts/FlexMdLayout.vue"),
      children: [
        {
          path: "onboarding",
          name: "flex.onboarding",
          meta: {
            requiresAuth: true,
            title: "Onboarding",
            roles: ["flex_admin", "flex_viewer", "administrator"],
          },
          component: () => import("../views/MyOnboarding.vue"),
        },
        {
          path: "dashboard",
          name: "flex.dashboard",
          meta: {
            title: "Dashboard",
          },
          component: () => import("../views/PublisherDashboard.vue"),
          // component: () => import("../views/flex/FlexDashboard.vue"),
        },
        {
          path: "billing",
          name: "flex.billing",
          meta: {
            requiresAuth: true,
            roles: ["administrator"],
            title: "Billing",
          },
          component: () => import("../views/flex/MyBilling.vue"),
        },
        {
          path: "advanced-reporting",
          name: "flex.advanced.reporting",
          meta: {
            title: "Advanced Reporting",
            roles: ["flex_admin", "flex_viewer", "administrator"],
          },
          component: RouterView,
          children: [
            {
              path: "",
              // NOTE: Please note that route name is used in the advanced reporting to determine if the route is flex so if these are changed
              // Please ensure that the computed method in the advanced reporting is also updated - used in EditReport, ReportFilter, ReportsList
              name: "flex.advanced.reporting.list",
              meta: {
                title: "Advanced Reporting List",
                disableBreadcrumbs: true,
                roles: ["flex_admin", "flex_viewer", "administrator"],
              },
              component: () => import("../views/reporting/ReportsList.vue"),
            },
            {
              path: ":id/edit",
              // NOTE: Please note that route name is used in the advanced reporting to determine if the route is flex so if these are changed
              // Please ensure that the computed method in the advanced reporting is also updated - used in EditReport, ReportFilter, ReportsList
              name: "flex.advanced.reporting.edit",
              meta: {
                title: "Advanced Reporting Edit",
                roles: ["flex_admin", "flex_viewer", "administrator"],
              },
              component: () => import("../views/reporting/EditReport.vue"),
            },
            {
              path: "create",
              // NOTE: Please note that route name is used in the advanced reporting to determine if the route is flex so if these are changed
              // Please ensure that the computed method in the advanced reporting is also updated - used in EditReport, ReportFilter, ReportsList
              name: "flex.advanced.reporting.create",
              meta: {
                title: "Advanced Reporting Create",
                roles: ["flex_admin", "flex_viewer", "administrator"],
              },
              component: () => import("../views/reporting/EditReport.vue"),
            },
            {
              path: "results/:id?",
              // NOTE: Please note that route name is used in the advanced reporting to determine if the route is flex so if these are changed
              // Please ensure that the computed method in the advanced reporting is also updated - used in EditReport, ReportFilter, ReportsList
              name: "flex.advanced.reporting.results",
              meta: {
                title: "Advanced Reporting Results",
                roles: ["flex_admin", "flex_viewer", "administrator"],
              },
              component: () => import("../views/reporting/ReportResults.vue"),
            },
          ],
        },
        {
          path: "ad-stacks",
          name: "flex.ad-stacks",
          meta: {
            title: "Ad Stacks",
            overrideTitle: true,
            requiresAuth: true,
            disableBreadcrumbs: true,
            roles: ["flex_admin", "flex_viewer", "administrator"],
          },
          component: () => import("../views/flex/AdStackListing.vue"),
        },
        {
          path: "ad-stacks/:ad_stack_id",
          name: "flex.ad-stacks.details",
          meta: {
            title: "Ad Stack Details",
            overrideTitle: true,
            requiresAuth: true,
            roles: ["flex_admin", "flex_viewer", "administrator"],
          },
          component: () =>
            import("../views/flex/adstack/details/AdStackDetail.vue"),
        },
        {
          path: "ad-stacks/:ad_stack_id/duplicate",
          name: "flex.ad-stacks.details.duplicate",
          meta: {
            title: "Ad Stack Details",
            overrideTitle: true,
            requiresAuth: true,
            roles: ["flex_admin"],
          },
          component: () => import("../views/flex/adstack/WizardAdStack.vue"),
        },
        {
          path: "ad-stacks/create/:siteid?",
          name: "flex.ad-stacks.create",
          meta: {
            title: "Create Ad Stack",
            overrideTitle: true,
            requiresAuth: true,
            roles: ["flex_admin"],
          },
          component: () => import("../views/flex/adstack/WizardAdStack.vue"),
        },
        {
          path: "site-config",
          name: "flex.site-config",
          meta: {
            title: "Site Configuration",
            disableBreadcrumbs: true,
          },
          component: RouterView,
          children: [
            {
              path: "",
              redirect: { name: "flex.ads.txt" },
            },
            {
              path: "network-settings",
              name: "flex.site-config.network-settings",
              meta: {
                title: "Network Settings",
                requiresAuth: true,
                roles: ["flex_admin", "flex_viewer", "administrator"],
              },
              redirect: { name: "flex.site-config.network-settings.demand" },
              children: [
                {
                  path: "demand",
                  name: "flex.site-config.network-settings.demand",
                  meta: {
                    title: "Network Settings",
                    requiresAuth: true,
                    disableBreadcrumbs: true,
                    roles: ["flex_admin", "flex_viewer", "administrator"],
                  },
                  component: () => import("../views/flex/NetworkSettings.vue"),
                },
                {
                  path: "identity",
                  name: "flex.site-config.network-settings.identity",
                  meta: {
                    title: "Network Settings",
                    requiresAuth: true,
                    disableBreadcrumbs: true,
                    roles: ["flex_admin", "flex_viewer", "administrator"],
                  },
                  component: () => import("../views/flex/NetworkSettings.vue"),
                },
                {
                  path: "flooring",
                  name: "flex.site-config.network-settings.flooring",
                  meta: {
                    title: "Network Settings",
                    requiresAuth: true,
                    disableBreadcrumbs: true,
                    roles: ["flex_admin", "flex_viewer", "administrator"],
                  },
                  component: () => import("../views/flex/NetworkSettings.vue"),
                },
                {
                  path: "ad-placements",
                  name: "flex.site-config.network-settings.ad-placements",
                  meta: {
                    title: "Network Settings",
                    requiresAuth: true,
                    disableBreadcrumbs: true,
                    roles: ["flex_admin", "flex_viewer", "administrator"],
                  },
                  component: () => import("../views/flex/NetworkSettings.vue"),
                },
              ],
            },
            {
              path: "network-settings/ad-placements/:adPlacementId",
              name: "flex.site-config.network-settings.ad-placement.details",
              meta: {
                title: "Ad Placement Details",
                overrideTitle: true,
                requiresAuth: true,
                roles: ["flex_admin", "flex_viewer", "administrator"],
              },
              component: () => import("../views/flex/AdPlacementDetail.vue"),
            },
            {
              path: "manage-users",
              name: "flex.site-config.users",
              meta: {
                title: "Manage Users",
                disableBreadcrumbs: true,
                roles: ["flex_admin"],
                fallbackRoute: "flex.dashboard",
              },
              component: () => import("../views/flex/ManageUsers.vue"),
            },
            {
              path: "general-settings",
              name: "flex.site-config.general-settings",
              meta: {
                title: "General Settings",
                requiresAuth: true,
                disableBreadcrumbs: true,
                roles: ["flex_admin", "flex_viewer", "administrator"],
              },
              component: () => import("../views/SiteConfig.vue"),
              children: [
                {
                  path: "ads-txt",
                  name: "flex.ads.txt",
                  meta: {
                    title: "Ads.txt",
                    disableBreadcrumbs: true,
                  },
                  alias: ["", "/"],
                  component: () =>
                    import("../components/siteconfig/AdsTxt.vue"),
                },
                {
                  path: "app-ads-txt",
                  name: "flex.app.ads.txt",
                  meta: {
                    title: "App Ads.txt",
                    disableBreadcrumbs: true,
                  },
                  component: () =>
                    import("../components/siteconfig/AdsTxt.vue"),
                },
                {
                  path: "ad-tags",
                  name: "flex.ad.tags",
                  meta: {
                    title: "Ad Tags",
                    disableBreadcrumbs: true,
                  },
                  component: () =>
                    import("../components/siteconfig/AdTags.vue"),
                },
                {
                  path: "amp",
                  name: "flex.amp.config",
                  meta: {
                    title: "AMP Config",
                    disableBreadcrumbs: true,
                  },
                  component: () =>
                    import("../components/siteconfig/AmpConfigDetails.vue"),
                },
                {
                  path: "api",
                  name: "flex.api.token.config",
                  meta: {
                    title: "API Tokens",
                    disableBreadcrumbs: true,
                  },
                  component: () =>
                    import("../components/siteconfig/ApiTokenConfig.vue"),
                },
                {
                  path: "ad-block",
                  name: "flex.ad.block.recovery",
                  meta: {
                    title: "Freestar Recovered",
                    disableBreadcrumbs: true,
                  },
                  component: () =>
                    import("../components/siteconfig/AdBlockRecovery.vue"),
                },
              ],
            },
          ],
        },
        {
          path: "ab-testing",
          name: "flex.ab-testing",
          meta: {
            roles: ["flex_admin", "flex_viewer", "administrator"],
            title: "A/B Testing",
            overrideTitle: true,
          },
          beforeEnter: () => {
            useAbTestStore().fetchAccountAbTests();
          },
          children: [
            {
              path: "",
              name: "flex.ab-testing.list",
              meta: {
                title: "A/B Testing",
                disableBreadcrumbs: true,
              },
              component: () => import("../views/flex/FlexAbTestDashboard.vue"),
            },
            {
              path: ":id",
              name: "flex.ab-testing.details",
              meta: {
                title: "Experiment Details",
                overrideTitle: true,
                headerTitleSpacer: false,
              },
              component: () => import("../views/flex/FlexAbTestDetails.vue"),
              beforeEnter: async (to, from, next) => {
                useAbTestViewStore().fetchAbTestView({
                  experimentId: String(to.params.id),
                });
                await useIdentityProvidersStore().getActiveIdentityProviders();
                await store.dispatch("networks/getAllActive");

                next();
              },
            },
          ],
        },
        {
          path: "marketplace",
          name: "flex.marketplace",
          meta: {
            title: "Marketplace",
            requiresAuth: true,
            roles: ["flex_admin", "flex_viewer", "administrator"],
          },
          component: () => import("../views/flex/marketPlace/MarketPlace.vue"),
        },
      ],
    },
    {
      path: "/:pathMatch(.*)",
      redirect: "/dashboard",
    },
  ],
});

// Guards
router.beforeEach(userImpersonationGuard);
router.beforeEach(flexRedirect);
router.beforeEach(checkAuthBefore);
router.beforeEach(checkRolesBefore);
router.beforeEach(redirectIfAuthenticated);
router.beforeEach(checkSitesAndCompanies);
router.beforeEach(setTitle);
router.beforeEach(createBreadcrumbGuard);

router.afterEach(googleAnalyticsAfterHook);
router.afterEach(pendoInitAfterHook);
router.afterEach(userpilotIdentifyAfterHook);

export default router;
