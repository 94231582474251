<script setup lang="ts">
import { RouterView } from "vue-router";
import { inject, onMounted } from "vue";
import { useAccessChecks } from "@/composables/useAccessChecks";
import type { Zendesk } from "@/plugins/zendesk";
import { useZendeskTokenStore } from "@/stores/zendesk";
import SwitchingAccountOverlay from "@/components/loading/SwitchingAccountOverlay.vue";
import { UsersStore } from "@/stores/user";
import DashboardImpersonateUserDialog from "@/components/DashboardImpersonateUserDialog.vue";
import { storeToRefs } from "pinia";

const $zendesk = inject<Zendesk>("$zendesk");

const zendeskTokenStore = useZendeskTokenStore();

const { isOnboardingOpenAndPodIdExists } = useAccessChecks();
/**
 * use store
 */
const userStore = UsersStore();

/**
 * user store references
 */
const { showUserImpersonationDialog } = storeToRefs(userStore);

onMounted(() => {
  zendeskTokenStore.fetchZendeskTokenWhenUserLogIn(
    async (newToken) => {
      if (newToken && $zendesk) {
        await $zendesk.load(); //loads zendesk script
        $zendesk.login(newToken); //authenticates zendesk user using authorization-api token

        if (!isOnboardingOpenAndPodIdExists.value) {
          $zendesk.show();
        } else {
          $zendesk.hide();
        }
      }
    },
    () => {
      if ($zendesk) {
        $zendesk.logout();
        $zendesk.hide();
      }
    }
  );
});
</script>

<template>
  <!-- overlays -->
  <SwitchingAccountOverlay>
    <template #message> switching account... </template>
  </SwitchingAccountOverlay>
  <!-- End of overlays -->

  <!-- global dialogs -->
  <dashboard-impersonate-user-dialog
    v-if="showUserImpersonationDialog"
    v-model="showUserImpersonationDialog"
    @on-cancel="userStore.toggleImpersonationDialog"
    @on-impersonate="userStore.toggleImpersonationDialog"
    @on-restore="userStore.toggleImpersonationDialog"
  />
  <!-- End global dialogs -->
  <RouterView />
</template>
